import axios from "axios";
import { v1 as uuidv1 } from "uuid";
import { AlreadySendingRequestException } from "../exceptions/AlreadySendingRequestException";
const nodeEnv = process.env.REACT_APP_API_WEBSITES_URL;

export const ambiente: any = nodeEnv?.includes("hml") ? "hml" : "prod";

const api = axios.create({
  baseURL: nodeEnv,
});

const apiObserver = {
  sendingLead: false,
};

api.interceptors.request.use(async (config: any) => {
  if (
    config.method.toLowerCase() === "post" &&
    config.url.toLowerCase().includes("melhor-proposta-website")
  ) {
    if (apiObserver.sendingLead) {
      throw new AlreadySendingRequestException("O formulário já está sendo enviado, por favor, aguarde antes de tentar novamente");
    }

    apiObserver.sendingLead = true;

    setTimeout(() => {
      apiObserver.sendingLead = false;
    }, 5000);
  }

  const RequestId = uuidv1();

  if (RequestId) {
    config.headers["RequestId"] = RequestId;
  }

  return config;
});

api.interceptors.response.use(
  (response: any) => {
    const { config } = response;

    if (
      config.method.toLowerCase() === "post" &&
      config.url.toLowerCase().includes("melhor-proposta-website")
    ) {
      apiObserver.sendingLead = false;
    }

    return response;
  },
  (error: any) => {
    console.log("error");
    console.log(error);
    return Promise.reject(error)
  }
);

export default api;
